@font-face {
  font-family: 'LineSeedExtraBold';
  src: url('./fonts/LINESeedSans_W_XBd.woff') format('woff2');
}

@font-face {
  font-family: 'LineSeedBold';
  src: url('./fonts/LINESeedSans_W_Bd.woff') format('woff2');
}

@font-face {
  font-family: 'LineSeed';
  src: url('./fonts/LINESeedSans_W_Rg.woff') format('woff2');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  font-family: 'LineSeed';
}

.subtitle {
  font-size: min(3.5vmin, 27px);
  text-transform: uppercase;
  font-family: 'LineSeedBold';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.brush-overlay {
  position: fixed;
  width: max(33vmin, 250px);
  bottom: 0%;
  transform: translateY(8%) translateX(50%);
  right: 25%;
  z-index: -1;
  user-select: none;
  transition: transform 0.5s ease-in-out;
}

.brush-overlay.hide {
  transform: translateY(100%);
}

.brush {
  width: 100%;
  height: auto;
}

.bottom-nav {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  gap: 40px;
  padding: 10px 20px;
}

.bottom-nav a {
  text-decoration: none;
  font-size: min(3vmin, 23px);
  color: black;
  font-family: 'LineSeed';
}

.bottom-nav a:hover {
  text-decoration: underline;
}
