.aperture {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: transform 0.75s ease-in-out;
    transition-delay: 0.5s;
}

.aperture.left {
    z-index: 4;
    transform: translateX(-50%);
}

.aperture.right {
    z-index: 3;
    transform: translateX(50%);
}

.aperture.top {
    z-index: 2;
    transform: translateY(-70%);
}

.aperture.bottom {
    z-index: 1;
    transform: translateY(70%);
}

.aperture.animate {
    transform: translateX(0%) translateY(0%);
}
