.start-button {
    background-image: url('./images/start.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    width: min(38vmin, 340px);
    height: min(25vmin, 220px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.25s ease;
    user-select: none;

    font-family: LineSeedExtraBold, sans-serif;
    font-size: min(3vmin, 30px);
    line-height: 1.5;
    text-align: center;
    color: white;
}

.start-button:hover {
    transform: scale(1.05);
}

.start-button-login {
    display: none;
}