.static-container  {
    max-width: 600px;
    padding: 20px;
}

.static-container > .bg-top-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}

.static-container > .bg-bottom-left {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}

.static-container > .bg-bottom-right {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.static-title {
    font-family: 'LineSeedBold';
    margin-bottom: 0;
}

.static-subtitle {
    margin-top: 0;
    font-family: 'LineSeedBold';
    color: #DEDEDE;
}

.static-body {
    text-align: center;
}

