.word {
    display: flex;
    justify-content: center;
}

.letter {
    font-size: min(15vmin, 110px);
    font-family: 'LineSeedExtraBold';
    font-weight: normal;
    margin: 0 5px;
    display: inline-block;
}
